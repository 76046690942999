<template>
  <div>
    <div class="header-box pt-5 pb-4 mb-5"></div>
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="device of deviceList.devices"
          :key="device.id"
        >
          <div class="card table-card">
            <div class="card-content">
              <div class="d-flex mb-3">
                <div class="pull-left mr-auto">
                  <span
                    class="badge badge-pill"
                    :class="statusColor(device.status)"
                    >{{ device.status == 'Active' ? "ATIVO" : device.status == 'Inactive'? "INATIVO" : "" }}</span
                  >
                </div>
              </div>
              <div class="d-flex mb-3">
                <div class="text-center mx-auto">
                  <h5 class="font-weight-light mb-2">
                    {{ device.name }}
                  </h5>
                  <p class="mb-3 text-muted">Cadastrado em: {{ device.createdAt | moment('DD/MM/YYYY') }}</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="text-center mx-auto">
                  <div class="dropdown text-center mx-auto">
                    <WhiteLabel>
                      <button
                        type="button"
                        class="btn btn-outline-orange btn-pill dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Ações
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li v-if="device.status == 'Active'">
                          <a
                            href="javascript:;"
                            class="dropdown-item"
                            data-toggle="modal"
                            :data-target="'#' + modalDeviceManagement"
                            @click.prevent="selectDeviceId(device.id, 'inactive')"
                            >Inativar</a
                          >
                        </li>
                        <li v-else-if="device.status == 'Inactive'">
                          <a
                            href="javascript:;"
                            class="dropdown-item"
                            data-toggle="modal"
                            :data-target="'#' + modalDeviceManagement"
                            @click.prevent="selectDeviceId(device.id, 'active')"
                            >Ativar</a
                          >
                        </li>
                         <li>
                          <a
                            href="javascript:;"
                            class="dropdown-item"
                            data-toggle="modal"
                            :data-target="'#' + modalDeviceManagement"
                            @click.prevent="selectDeviceId(device.id, 'delete')"
                            >Excluir</a
                          >
                        </li>
                      </ul>
                    </WhiteLabel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <DeviceManagementModal
        v-if="deviceId"
        :id="modalDeviceManagement"
        :deviceId="deviceId"
        :action="action"
        @reset="reset()"
        />
      <div v-if="deviceList.count === 0">
        <div class="text-center">
          <h4>Nenhum dispositivo encontrado.</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import WhiteLabel from '@/components/shared/WhiteLabel';
import DeviceApi from '@/services/DeviceApi';
import { mask } from 'vue-the-mask';
import { DeviceList } from '@/models/Device';
import DeviceManagementModal from '@/components/device/DeviceManagementModal.vue';

export default {
  name: 'DevicesList',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.search();

    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      deviceList: new DeviceList(),
      fingerPrint: null,
      deviceId: null,
      action: '',
      perPage: 12,
      count: 0,
      modalDeviceManagement: 'modalDeviceManagement',
    };
  },
  directives: {
    mask,
  },
  computed: {
  },
  methods: {
    async reset() {
      $(`#${this.modalDeviceManagement}`).modal('hide');
      this.action = '';
      await this.search();
    },

    async selectDeviceId(deviceId, action) {
      this.deviceId = deviceId.toString();
      this.action = action;
    },

    statusColor(type) {
      return {
        'badge-success': type == 'Active',
        'badge-danger': type == 'Inactive',
      };
    },
    async search() {
      this.$bus.$emit('spinner-run');
      const api = new DeviceApi();
      this.deviceList = await api.listDevices();
      this.deviceList.count = this.deviceList.devices.length;
      this.$bus.$emit('spinner-stop');
    },
  },
  components: {
    WhiteLabel,
    DeviceManagementModal,
  },
};
</script>
