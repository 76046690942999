import CustomApiError from './CustomError';

export default class UserError extends CustomApiError {
  toDictionary() {
    return {
      EMAIL_ALREADY_TAKEN: 'Este e-mail já está sendo utilizado.',
      DUPLICATE_TAX_DOCUMENT: 'CPF/CNPJ já cadastrado.',
      ALREADY_ACTIVE: 'O usuário já está ativo.',
      ALREADY_INACTIVE: 'O usuário já está inativo.',
    };
  }
}
