<template>
  <div class="mt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-w-full col-lg-8">
          <div class="card">
            <form @submit.prevent="save()">
              <div class="d-flex">
                <WhiteLabel>
                  <h3 class="font-weight-light ml-3 pull-left">
                    Dados do Usuário
                  </h3>
                </WhiteLabel>
                <div class="pull-right ml-auto mr-3">
                  <label class="switch switch-danger switch-to-success">
                    <span class="mr-2">{{
                      user.active ? "Ativo" : "Inativo"
                    }}</span>
                    <input type="checkbox" v-model="user.active" />
                    <span class="switch-slider"></span>
                  </label>
                </div>
              </div>
              <hr />
              <div class="card-content">
                <div class="form-row">
                  <div class="form-group col-12 col-w-full col-md-7 mb-4">
                    <label for="inputNome">Nome *</label>
                    <input
                      type="text"
                      class="form-control"
                      @input="$v.user.fullName.$touch()"
                      v-model="user.fullName"
                      maxlength="120"
                      placeholder="Digite aqui o nome do usuário"
                    />
                    <small
                      class="text-danger"
                      v-if="
                        !$v.user.fullName.required &&
                        this.$v.user.fullName.$dirty
                      "
                      >Campo obrigatório.</small
                    >
                    <small
                      class="text-danger"
                      v-if="
                        $v.user.fullName.$invalid &&
                        this.$v.user.fullName.$dirty
                      "
                      >Somente letras.</small
                    >
                  </div>
                  <div class="form-group col-12 col-w-full col-md-5 mb-4">
                    <label for="inputCPF">CPF *</label>
                    <input
                      type="text"
                      class="form-control"
                      @input="$v.user.taxDocument.$touch()"
                      v-model="user.taxDocument"
                      maxlength="18"
                      v-mask="'###.###.###-##'"
                      placeholder="000.000.000-00"
                    />
                    <small
                      class="text-danger"
                      v-if="
                        !$v.user.taxDocument.required &&
                        this.$v.user.taxDocument.$dirty
                      "
                      >Campo obrigatório.</small
                    >
                    <small
                      class="text-danger"
                      v-if="
                        $v.user.taxDocument.$invalid &&
                        this.$v.user.taxDocument.$dirty
                      "
                      >Digite um CPF válido.</small
                    >
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12 col-w-full col-md-7 mb-4">
                    <label for="inputEmail">E-mail *</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="user.email"
                      @input="$v.user.email.$touch()"
                      placeholder="Digite aqui o e-mail do usuário."
                    />
                    <small class="text-danger" v-if="emailIsInvalid"
                      >Campo obrigatório.</small
                    >
                    <small
                      class="text-danger"
                      v-if="$v.user.email.$invalid && this.$v.user.email.$dirty"
                      >Digite um e-mail válido.</small
                    >
                  </div>
                  <div class="form-group col-12 col-w-full col-md-5 mb-5">
                    <label for="inputRole">Perfil de usuário *</label>
                    <select
                      class="form-control"
                      name="role"
                      v-model="user.role"
                    >
                      <option value>Selecione</option>
                      <option
                        v-for="role in allRoles.roles"
                        :key="role.id"
                        :value="role"
                      >
                        {{ role.name }}
                      </option>
                    </select>
                    <small
                      class="text-danger"
                      v-if="$v.user.role.$invalid && this.$v.user.role.$dirty"
                      >Campo obrigatório.</small
                    >
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <WhiteLabel class="m-auto">
                    <router-link
                      class="btn btn-md btn-outline-orange mr-2"
                      :to="{ name: 'user' }"
                      >Cancelar</router-link
                    >

                    <button type="submit" class="btn btn-md btn-orange">
                      Salvar
                    </button>
                  </WhiteLabel>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { User } from '@/models/User';
import { required, email } from 'vuelidate/lib/validators';
import { cpf } from '@/validations/cpf';
import { propName } from '@/validations/propName';
import AccountApi from '@/services/AccountApi';
import Datepicker from 'vuejs-datepicker';
import { RoleFilter } from '@/models/Role';
import UserError from '@/errors/UserError';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppEditUser',
  async mounted() {
    await this.searchRoles();
    this.$bus.$emit('spinner-run');
    await this.findUser();
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      user: new User(),
      listRoles: [],
    };
  },
  components: {
    WhiteLabel,
  },
  methods: {
    async searchRoles() {
      const api = new AccountApi();
      this.listRoles = await api.roles.search(new RoleFilter());
    },
    async save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        const api = new AccountApi();
        this.$bus.$emit('spinner-run');
        if (this.$route.params.userId) {
          const result = await api.users.update(this.user);
          if (result.error) {
            const title = 'Ops';
            const modelError = new UserError(result);
            const error = modelError.getMessage();
            this.$alert.error(
              title,
              `O usuário ${this.user.fullName} não pôde ser atualizado. ${error}`
            );
            this.$bus.$emit('spinner-stop');
          } else {
            const title = 'Usuário atualizado';
            this.$alert.onClose = async() =>
              this.$router.push({ name: 'user' });
            this.$alert.info(
              title,
              `O usuário ${this.user.fullName} foi atualizado com sucesso.`
            );
            this.$bus.$emit('spinner-stop');
          }
        } else {
          const result = await api.users.save(this.user);
          if (result.error) {
            const title = 'Ops';
            const modelError = new UserError(result);
            const error = modelError.getMessage();
            this.$alert.error(
              title,
              `O usuário ${this.user.fullName} não pôde ser cadastrado. ${error}`
            );
            this.$bus.$emit('spinner-stop');
          } else {
            const title = 'Usuário cadastrado';
            this.$alert.onClose = async() =>
              this.$router.push({ name: 'user' });
            this.$alert.info(
              title,
              `O usuário ${this.user.fullName} foi cadastrado com sucesso.`
            );
            this.$bus.$emit('spinner-stop');
          }
        }
      }
    },
    back() {
      this.$router.push({ name: 'user' });
      location.reload();
    },
    async findUser() {
      if (!this.$route.params.userId) {
        this.user.role = '';
      } else {
        const api = new AccountApi();
        var response = await api.users.find(this.$route.params.userId);
        this.user = response;
      }
    },
  },
  validations: {
    user: {
      fullName: {
        required,
        propName,
      },
      taxDocument: {
        cpf,
        required,
      },
      email: {
        email,
        required,
      },
      role: {
        required,
      },
    },
  },
  computed: {
    nameIsInvalid() {
      return this.$v.user.fullName.$invalid && this.$v.user.fullName.$dirty;
    },
    taxDocumentIsInvalid() {
      return (
        this.$v.user.taxDocument.$invalid && this.$v.user.taxDocument.$dirty
      );
    },
    isEmpty() {
      return (
        (this.user.role === '' || !this.user.role) && this.$v.user.role.$dirty
      );
    },
    emailIsInvalid() {
      return (
        (!this.user.email || this.$v.user.email.$invalid) &&
        this.$v.user.email.$dirty
      );
    },
    allRoles() {
      return this.listRoles;
    },
  },
};
</script>
