import { Offset } from '@/models/Offset.js';

export class Role {
  id = 0
  name = null
  totalUsers = null
  active = true
}

export class RoleFilter {
  name = ''
  offset = new Offset()
}

export class RoleList {
  roles = null
  count = 0
}

export class RoleParams {
  id = 0
  name = ''
  active = true

  constructor(role) {
    this.id = role.id;
    this.name = role.name;
    this.active = role.active;
  }
}
