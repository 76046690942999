export class Device {
  id = null
  name = ''
  createdAt = null
  updatedAt = null
  status = 'active'
  fingerPrint = ''
}

export class DeviceParams {
  id = null
  decription = ''
  createdAt = null
  updatedAt = null
  fingerPrint = null
  status = true

  constructor(device) {
    this.id = device.id;
    this.name = device.name;
    this.createdAt = device.createdAt;
    this.status = device.status;
    this.updatedAt = device.updatedAt;
  }
}

export class DeviceList {
  devices = [];
  count = 0;
}
