<template>
  <div>
    <div class="header-box pt-5 pb-4 mb-5">
      <div class="row justify-content-center mx-0">
        <div class="col-12 col-w-full col-xl-10">
          <div class="col-sm-12 mb-4">
            <WhiteLabel>
              <router-link
                :to="{ name: 'new-user' }"
                class="btn btn-orange btn-pill align-self"
                v-if="usersPermission"
              >
                <i class="icon-plus"></i> Novo usuário
              </router-link>
            </WhiteLabel>
          </div>
          <form @submit.prevent="search" autocomplete="off" class="row mx-0">
            <div class="col-sm-12 col-lg-3 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  v-model="userFilter.fullName"
                  class="form-control"
                  name="name"
                  placeholder="Nome"
                  maxlength="80"
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-3 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  v-model="userFilter.email"
                  class="form-control"
                  name="code"
                  placeholder="E-mail"
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-3 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  v-model="userFilter.taxDocument"
                  class="form-control"
                  name="code"
                  placeholder="CPF"
                  v-mask="'###.###.###-##'"
                />
              </div>
            </div>
            <div class="d-flex col-sm-12 col-md-6 col-lg-3 mb-3">
              <WhiteLabel>
                <button
                  class="btn btn-md btn-orange mr-2"
                  :disabled="$v.$invalid"
                >
                  Pesquisar
                </button>
                <button
                  type="button"
                  @click="resetFilters"
                  class="btn btn-md btn-outline-orange ml-2"
                >
                  Limpar filtro
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="user of userList.users"
          :key="user.id"
        >
          <div class="card table-card">
            <div class="card-content">
              <div class="d-flex mb-3">
                <div class="pull-left mr-auto">
                  <span
                    class="badge badge-pill"
                    :class="statusColor(user.active)"
                    >{{ user.active ? "ATIVO" : "INATIVO" }}</span
                  >
                </div>
              </div>
              <div class="d-flex mb-3">
                <div class="text-center mx-auto">
                  <h5 class="font-weight-light mb-2">
                    {{ user.fullName | abbreviateName(20) }}
                  </h5>
                  <p class="mb-3 text-muted">{{ user.taxDocument }}</p>
                  <p class="mb-3 text-muted">{{ user.email }}</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="text-center mx-auto">
                  <div
                    class="dropdown text-center mx-auto"
                    v-if="usersPermission"
                  >
                    <WhiteLabel>
                      <button
                        type="button"
                        class="btn btn-outline-orange btn-pill dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Ações
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link
                            v-if="user.id"
                            class="dropdown-item"
                            :to="{
                              name: 'edit-user',
                              params: { userId: user.id },
                            }"
                            >Editar</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            v-if="user.id"
                            class="dropdown-item"
                            :to="{
                              name: 'show-user',
                              params: { userId: user.id },
                            }"
                            >Visualizar</router-link
                          >
                        </li>
                        <li v-if="user.active">
                          <a
                            href="javascript:;"
                            class="dropdown-item"
                            @click.prevent="inactive(user)"
                            >Inativar</a
                          >
                        </li>
                        <li v-else>
                          <a
                            href="javascript:;"
                            class="dropdown-item"
                            @click.prevent="active(user)"
                            >Ativar</a
                          >
                        </li>
                      </ul>
                    </WhiteLabel>
                  </div>
                  <router-link
                    :to="{ name: 'show-user', params: { userId: user.id } }"
                    class="btn btn-outline-orange btn-pill"
                    v-if="!usersPermission"
                    >Visualizar</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="userList.count === 0">
        <div class="text-center">
          <h4>Nenhum usuário encontrado.</h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="userList.count"
          :perPage="perPage"
          @exchange="turnPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppPaginator from '@/components/theme/Paginator';
import WhiteLabel from '@/components/shared/WhiteLabel';
import AccountApi from '@/services/AccountApi';
import { mask } from 'vue-the-mask';
import { email } from 'vuelidate/lib/validators';
import { cpf } from '@/validations/cpf';
import { UserFilter, UserList } from '@/models/User';
import { PermissionList } from '@/models/Permission';

export default {
  name: 'AppSearchUser',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.askWhoAmI();
    await this.search();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes(this.whoAmI.user.role.scopes);
    }
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      userList: new UserList(),
      userFilter: new UserFilter(),
      permissionList: new PermissionList(),
      perPage: 12,
      count: 0,
    };
  },
  directives: {
    mask,
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    usersPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'account.users'
      );
    },
    userName() {
      if (!this.whoAmI) return '';
      return this.whoAmI.user.fullName;
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    usersPermission() {
      if (
        (this.permissionList.permissions[this.usersPermissionIndex].grant ===
          'edit' ||
          this.isAccountHolder) &&
        this.isAccountActive
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
      exit: 'account/logout',
    }),
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    resetFilters() {
      this.userFilter = new UserFilter();
    },
    async inactive(user) {
      this.$alert.question(
        'Inativar usuário',
        `Inativar o usuário ${user.fullName}?`
      );
      this.$alert.onConfirm = async() => {
        this.$bus.$emit('spinner-run');
        const api = new AccountApi();
        await api.users.deactivate(user.id);
        this.$bus.$emit('spinner-stop');
        await this.search();
      };
    },
    async active(user) {
      this.$alert.question(
        'Ativar usuário',
        `Ativar o usuário ${user.fullName}?`
      );
      this.$alert.onConfirm = async() => {
        this.$bus.$emit('spinner-run');
        const api = new AccountApi();
        await api.users.activate(user.id);
        this.$bus.$emit('spinner-stop');
        await this.search();
      };
    },
    statusColor(type) {
      return {
        'badge-success': type,
        'badge-danger': !type,
      };
    },
    async search() {
      this.$bus.$emit('spinner-run');
      const api = new AccountApi();
      this.userList = await api.users.search(this.userFilter);
      this.userList.users = this.userList.users.slice(0, this.perPage);
      this.$bus.$emit('spinner-stop');
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.userFilter.offset.index = inicio;
      await this.search();
    },
  },
  validations: {
    userFilter: {
      email: { email },
      taxDocument: { cpf },
    },
  },
  components: {
    AppPaginator,
    WhiteLabel,
  },
};
</script>
