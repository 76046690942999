import axios from 'axios';

export default class DeviceApi {
  async createDevice(deviceId, requestId, authCode) {
    const options = { headers: { authCode: authCode } };
    const model = {
      fingerPrint: deviceId,
      requestId: requestId,
    };

    const result = await axios.post(`${process.env.VUE_APP_DEVICE_API_URL}`,
      model,
      options);

    return result;
  }

   async inactiveDevice(deviceId, authCode) {
    const options = { headers: { authCode: authCode } };
    const url = `${process.env.VUE_APP_DEVICE_API_URL}/${deviceId}/deactivate`;
    const result = await axios.put(url, null, options);

    return result;
  }

  async activeDevice(deviceId, authCode) {
    const options = { headers: { authCode: authCode } };

    const url = `${process.env.VUE_APP_DEVICE_API_URL}/${deviceId}/activate`;
    const result = await axios.put(url, null, options);

    return result;
  }

  async listDevices() {
    const url = `${process.env.VUE_APP_DEVICE_API_URL}`;
    let result = await axios.get(url);

    return result.data;
  }

  async deleteDevice(deviceId, authCode) {
    const options = { headers: { authCode: authCode } };
    const url = `${process.env.VUE_APP_DEVICE_API_URL}/${deviceId}`;
    const result = await axios.delete(url, options);

    return result;
  }

   async getDevice(fingerPrint) {
    const url = `${process.env.VUE_APP_DEVICE_API_URL}/${fingerPrint}`;
    const result = await axios.get(url);

    return result;
  }
}
