<template>
  <div class="mt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-w-full col-lg-8">
          <div class="card">
            <WhiteLabel class="d-flex">
              <h3 class="font-weight-light pull-left ml-3">
                Usuário: {{ user.fullName }}
              </h3>
              <router-link
                :to="{ name: 'user' }"
                class="ml-auto mt-1 pull-right mr-4"
              >
                <i class="icon-arrow-left-circle"></i> Voltar
              </router-link>
            </WhiteLabel>

            <hr />
            <div class="card-content">
              <form @submit.prevent="save" autocomplete="off">
                <WhiteLabel>
                  <h4 class="font-weight-light blue">Dados</h4>
                </WhiteLabel>
                <div class="mb-5">
                  <p>CPF: {{ user.taxDocument }}</p>
                  <p>
                    Situação:
                    <span class="badge badge-danger" v-if="!user.active"
                      >INATIVO</span
                    >
                    <span class="badge badge-success" v-if="user.active"
                      >ATIVO</span
                    >
                  </p>
                  <p>
                    Perfil de usuário:
                    <span v-if="user.role">{{ user.role.name }}</span>
                  </p>
                </div>
                <WhiteLabel>
                  <h4 class="font-weight-light blue">Contato</h4>
                </WhiteLabel>
                <div class="mb-5">
                  <p>E-mail: {{ user.email }}</p>
                </div>

                <WhiteLabel class="d-flex mb-4">
                  <router-link
                    :to="{ name: 'edit-user' }"
                    class="btn btn-orange mx-auto"
                    v-if="usersPermission"
                    >Editar usuário</router-link
                  >
                </WhiteLabel>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { mapActions, mapGetters } from 'vuex';
import AccountApi from '@/services/AccountApi';
import { UserFilter } from '@/models/User';
import { PermissionList } from '@/models/Permission';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppShowUser',
  components: {
    WhiteLabel,
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.askWhoAmI();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
      this.$bus.$emit('refresh-warnings');
    }
    await this.find();
    $(() => $('[data-toggle="popover"]').popover());
    this.$bus.$emit('spinner-stop');
  },
  data() {
    return {
      user: new UserFilter(),
      permissionList: new PermissionList(),
    };
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    usersPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'account.users'
      );
    },
    userName() {
      if (!this.whoAmI) return '';
      return this.whoAmI.user.fullName;
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    usersPermission() {
      if (
        (this.permissionList.permissions[this.usersPermissionIndex].grant ===
          'edit' ||
          this.isAccountHolder) &&
        this.isAccountActive
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
      exit: 'account/logout',
    }),
    async getScopes(id) {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    async find() {
      this.$bus.$emit('spinner-run');

      const api = new AccountApi();
      this.user = await api.users.find(this.$route.params.userId);

      this.$bus.$emit('spinner-stop');
    },
  },
};
</script>
