import CustomApiError from './CustomError';

export default class DeviceApiError extends CustomApiError {
  toDictionary() {
    return {
      UNAUTHORIZED_DEVICE: 'Dispositivo não autorizado.',
      LIMIT_EXCEEDED_FOR_UNAUTHORIZED_DEVICE: 'Limite excedido para dispositivo não autorizado.',
      'Invalid AuthCode.': 'Código de autorização inválido.',
      'Something went wrong.': 'Algo deu errado.',
      'One or more validation errors occurred.': 'Ocorreu um ou mais erros de validação.',
      'Resource not found.': 'Recurso não encontrado.',
      'Device not found.': 'Dispositivo não encontrado.',
      'Authorize request failed.': 'Falha na solicitação de autorização.',
      'Device already active.': 'Dispositivo já ativo.',
      'Device already inactive.': 'Dispositivo já inativo.',
      'Device already registered.': 'Dispositivo já registrado.',
      'Device event not found.': 'Evento de dispositivo não encontrado.',

    };
  }
}
