<template>
  <ModalBase
    :title="page.title"
    :hasFooter="false"
    size="transference-size"
    :id="id"
  >
    <div slot="content">
      <InactiveDeviceModalSection
        v-if="page.id == sections.inactiveDevice.id"
         @inactive-device="inactiveDevice()"
        />
      <ActiveDeviceModalSection
        v-if="page.id == sections.activeDevice.id"
         @active-device="activeDevice()"
      />
      <DeleteDeviceModalSection
        v-if="page.id == sections.deleteDevice.id"
        @delete-device="deleteDevice()"
      />
      <AuthCodeModalSection
        v-if="page.id === sections.authCode.id"
        :subSection="sections.authCode.subSection"
        :mobile="preset.holder.mobile"
        :email="preset.holder.email"
        :authCodeType="authCodeType"
        @auth-sent="authSent($event)"
        @code-ready="recieveAuthCode($event)"
      />
    </div>
  </ModalBase>
</template>

<script>

import ModalBase from '@/components/shared/ModalBase';
import AuthCodeModalSection from '@/components/pix/transactions/transfer/AuthCodeModalSection.vue';
import InactiveDeviceModalSection from '@/components/device/InactiveDeviceModalSection.vue';
import ActiveDeviceModalSection from '@/components/device/ActiveDeviceModalSection.vue';
import DeleteDeviceModalSection from '@/components/device/DeleteDeviceModalSection.vue';
import swal from 'sweetalert2';
import AccountApi from '@/services/AccountApi';
import DeviceApi from '@/services/DeviceApi';
import DeviceApiError from '@/errors/DeviceApiError';

export default {
  name: 'DeviceManagementModal',

  components: {
    ModalBase,
    AuthCodeModalSection,
    InactiveDeviceModalSection,
    ActiveDeviceModalSection,
    DeleteDeviceModalSection,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    deviceId: {
    type: [String, null],
    default: null,
    },
    action: {
      type: [String, null],
      default: null,
    },
  },

  data: () => ({
    authCodeType: null,
    requestId: null,
    preset: null,
    page: {
      id: 'inactiveDevice',
      title: 'Deseja inativar este dispositivo?',
    },
    sections: {
      inactiveDevice: {
        title: 'Deseja inativar este dispositivo?',
        id: 'inactiveDevice',
      },
      activeDevice: {
        title: 'Deseja reativar este dispositivo?',
        id: 'activeDevice',
      },
      deleteDevice: {
        title: 'Deseja excluir este dispositivo?',
        id: 'deleteDevice',
      },
      authCode: {
        title: 'Como deseja receber o código de autenticação?',
        subSection: 'destination',
        id: 'authCode',
      },
    },
  }),

  async created() {
    await this.loadPresets();
    this.checkPages();
  },

  async mounted() {
    this.checkPages();
    this.$bus.$on('cleanInput',(result) => {
    this.reset();
    });
  },

  watch: {
    action: function () {
       this.checkPages();
    },
  },

  methods: {
    reset() {
      this.sections.authCode.title = 'Como deseja receber o código de autenticação?';
      this.sections.authCode.subSection = 'destination';
      this.$emit('reset');
    },

    checkPages() {
     switch (this.action) {
       case 'inactive':
        this.setPage(this.sections.inactiveDevice);
        break;
        case 'active':
         this.setPage(this.sections.activeDevice);
         break;
       case 'delete':
        this.setPage(this.sections.deleteDevice);
         break;
     }
    },

    async loadPresets() {
      const api = new AccountApi();
      this.preset = await api.presets();
    },

    setPage(section) {
      this.page.id = section.id;
      this.page.title = section.title;
    },

    inactiveDevice() {
      this.authCodeType = 'device-control';
      this.setPage(this.sections.authCode);
    },

    activeDevice() {
      this.authCodeType = 'device-control';
      this.setPage(this.sections.authCode);
    },

    deleteDevice() {
      this.authCodeType = 'device-control';
      this.setPage(this.sections.authCode);
    },

    authSent(mobile) {
      this.sections.authCode.title = `Insira o código enviado para seu ${
        mobile ? 'celular' : 'email'
      }.`;

      this.sections.authCode.subSection = 'code';
      this.setPage(this.sections.authCode);
    },

    async inactiveDeviceAsync(authCode) {
      const api = new DeviceApi();
      let result = await api.inactiveDevice(this.deviceId, authCode);
      if (result.status == 200) {
          this.$alert.info('Dispositivo inativado!', '');
          swal({
            title: 'Dispositivo inativado!',
            text: '',
            type: 'success',
            allowOutsideClick: false,
          }).then(() => {
            this.reset();
          });
      } else if (result.status >= 400 || result.status <= 500) {
        const modelError = new DeviceApiError({
          error: result.data.title,
        });

        this.$alert.error('Ops', modelError.getMessage());
        this.reset();
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
        this.reset();
      }

      this.$bus.$emit('spinner-stop');
    },

    async activeDeviceAsync(authCode) {
      const api = new DeviceApi();
      let result = await api.activeDevice(this.deviceId, authCode);
      if (result.status == 200) {
          this.$alert.info('Dispositivo reativado!', '');
          swal({
            title: 'Dispositivo reativado!',
            text: '',
            type: 'success',
            allowOutsideClick: false,
          }).then(() => {
            this.reset();
          });
      } else if (result.status >= 400 || result.status <= 500) {
        const modelError = new DeviceApiError({
          error: result.data.title,
        });

        this.$alert.error('Ops', modelError.getMessage());
        this.reset();
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
        this.reset();
      }

      this.$bus.$emit('spinner-stop');
    },

    async deleteDeviceAsync(authCode) {
      const api = new DeviceApi();
      let result = await api.deleteDevice(this.deviceId, authCode);
      if (result.status == 204) {
          this.$alert.info('Dispositivo excluído!', '');
          swal({
            title: 'Dispositivo excluído!',
            text: '',
            type: 'success',
            allowOutsideClick: false,
          }).then(() => {
            this.reset();
          });
      } else if (result.status >= 400 || result.status <= 500) {
          const modelError = new DeviceApiError({
          error: result.data.title,
        });

        this.$alert.error('Ops', modelError.getMessage());
        this.reset();
      } else {
        this.$alert.error('Ops', 'Algo deu errado');
        this.reset();
      }

      this.$bus.$emit('spinner-stop');
    },

    async recieveAuthCode(authCode) {
      switch (this.action) {
        case 'inactive':
          await this.inactiveDeviceAsync(authCode);
          break;
        case 'active':
          await this.activeDeviceAsync(authCode);
          break;
        case 'delete':
          await this.deleteDeviceAsync(authCode);
          break;
     }
    },
  },
};
</script>
