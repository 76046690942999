<template>
  <div class="form-group col-12 col-w-full">
    <form action="#" @submit.prevent="activeDevice()">
      <div class="d-flow ml-2 mb-4">
          <h5 class="">
          É permitido realizar pix acima de R$ 200,00 por transação ou R$ 1000,00 por dia, através de um dispositivo ativo.
          Deseja continuar?
          </h5>
      </div>
      <WhiteLabel class="mb-4 col-12">
        <button type="submit" class="btn btn-md btn-orange col-12">
          Reativar dispositivo
        </button>
        <small class="text-muted d-block mt-1">Um código de autenticação será enviado</small>
      </WhiteLabel>
    </form>
  </div>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'ActiveDeviceModalSection',
  components: {
    WhiteLabel,
  },
  methods: {
    activeDevice() {
      this.$emit('active-device');
    },
  },
};
</script>
